
import { defineComponent, ref, onMounted, computed } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ManualOrderService from "@/core/services/car/ManualOrderService";
import useClipboard from "vue-clipboard3";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import {customFunction, roles } from "@/store/stateless/store";
  import InvoiceService from "@/core/services/car/InvoiceService";

interface InvoiceBodyParam {
  per_page: string;
  agent: string;
  payment_type: any;
  is_amend: number;
  status: any;
  keyword: string;
  start_date: string;
  end_date: string;
  is_draft_manual_order: number;
}

interface ApproveInvoiceParam {
  invoice_id: number;
  status: any;
}

export default defineComponent({
  name: "manual-order-list",
  components: {
    // Overlay,
  },
  data() {
    return {
      // opened: false,
      // visible: false,
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      value1: "",
      value2: "",
      invoiceStatus: [
        { id: "1", name: "Pending" },
        { id: "2", name: "Approve" },
        { id: "2", name: "Approve with Discrepancy" },
        { id: "3", name: "Decline" }
      ]
    };
  },
  setup() {
    // let customers_obj = ref([]);
    const route = useRoute();
    const routeName = route.name;

    const { toClipboard } = useClipboard();
    let userObj: any = {};
    let userStr: any = {};
    let daterange = ref<any>();

    let approveInvoiceParam = {} as ApproveInvoiceParam;
    const store = useStore();
    let bodyParameters = {} as InvoiceBodyParam;
    let exportUrl = ref<string>("");
    bodyParameters = {
      per_page: "50",
      agent: "all",
      payment_type: -1,
      is_amend: -1,
      status: -1,
      keyword: "",
      start_date: "",
      end_date: "",
      is_draft_manual_order: routeName == "manual-order-view" ? 0 : 1
    };

    userStr = LocalStorageServices.getUser();
    userObj = JSON.parse(userStr);

    onMounted(() => {

      if(routeName === 'manual-order-view' && !roles.viewManualOrders.includes(roles.userId())) window.location.href = '/#/404'
      if(routeName !== 'manual-order-view' && !roles.createManualOrders.includes(roles.userId())) window.location.href = '/#/404'

      MenuComponent.reinitialization();

      const title =
        routeName == "manual-order-view"
          ? "View Manual Orders"
          : "Create Manual Orders";
      setCurrentPageBreadcrumbs(title, []);
      // API Action to get customers list
      bodyParameters.keyword = "";
      getManualOrders(bodyParameters);
    });

    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      bodyParameters.keyword = "";
      bodyParameters.agent = userObj.role_id == 1 ? "all" : "mine";
      ManualOrderService.getManualOrderList(bodyParameters, currentPage.value);
    };
    const onPerPageChange = (event) => {
      bodyParameters.per_page = event.target.value;
      getManualOrders(bodyParameters);
    };

    const getManualOrders = (bodyParameters) => {
      currentPage.value = 1;
      bodyParameters.agent = userObj.role_id == 1 ? "all" : "mine";
      ManualOrderService.getManualOrderList(bodyParameters);
    };
    const getManualOrdersPeriod = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
      var endTS = new Date(listPeriod[1]);
      bodyParameters.start_date =
        startTS.getFullYear() +
        "-" +
        (startTS.getMonth() + 1) +
        "-" +
        startTS.getDate();
      bodyParameters.end_date =
        endTS.getFullYear() +
        "-" +
        (endTS.getMonth() + 1) +
        "-" +
        endTS.getDate();
      } else {
        bodyParameters.start_date = '';
        bodyParameters.end_date = '';
      }
      // bodyParameters.keyword = "";
      getManualOrders(bodyParameters);
    };

    const search = ref<string>("");
    const searchInvoice = () => {
      bodyParameters.keyword = search.value;
      //
      getManualOrders(bodyParameters);
    };
    const resetBodyParam = () => {
      search.value = "";
      bodyParameters.per_page = "50";
      bodyParameters.agent = "all";
      bodyParameters.payment_type = -1;
      bodyParameters.is_amend = -1;
      bodyParameters.status = -1;
      bodyParameters.keyword = "";
      bodyParameters.start_date = "";
      bodyParameters.end_date = "";
      bodyParameters.agent = userObj.role_id == 1 ? "all" : "mine";
      daterange.value = "";
      getManualOrders(bodyParameters);
    };

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });
    let loadApprovalInformation = computed(() => {
      return store.getters.getApprovalListingDom;
    });
    const userPermission = computed(() => {
      return store.getters.getUserPermissions;
    });
    const copyLink = async (link) => {
      try {
        await toClipboard(link);
        console.log("Copied to clipboard");
      } catch (e) {
        console.error(e);
      }
    };

    const paginatioObject = computed(() => {
      return store.getters.getManualOrderPaginationObject;
    });

    const currentPage = ref(1);

    const loading = computed(() => {
      return store.getters.getMoLoading;
    });

    const load = async(
        row,
        treeNode: unknown,
        resolve) => {
          let parent = false;
          if(row.is_manual_order === 1) {
            parent = row.parent_invoice_id === 0 ? false : true;
          } else {
            parent = row.is_amend === 0 ? false : true
          }

          const result = await InvoiceService.getChildInvoice({ 
            parent_invoice_id: row.is_amend === 0 ? row.id : row.parent_invoice_id, 
            parent, 
            invoice_id: row.is_amend === 1 ? row.id : row.parent_invoice_id, 
            is_manual_order: row.is_manual_order });
          resolve(result)
      }

    return {
      routeName,
      customFunction,
      load,
      loading,
      paginatioObject,
      currentPage,
      search,
      searchInvoice,
      onPerPageChange,
      getPaginationUrl,
      bodyParameters,
      getManualOrdersPeriod,
      resetBodyParam,
      disableSaveButton,
      exportUrl,
      loadApprovalInformation,
      userPermission,
      copyLink,
      daterange
    };
  }
});
